// 主屏
import request from "@/utils/request";
import store from "../store";
import { CancelToken } from "axios";
// 智慧食堂食安指数
export function foodSafetyIndex(query = {}) {
  return request({
    url: "/head/quarter/data/food/safety/index",
    method: "get",
    params: query,
  });
}
// 食堂专管分数列表
export function shopScoreList(query = {}) {
  return request({
    url: "/head/quarter/data/shop/score/list",
    method: "get",
    params: query,
  });
}
// 食堂概览
export function shopStatistics(query = {}) {
  return request({
    url: "/head/quarter/data/overview/of/shop",
    method: "get",
    params: query,
  });
}
// 菜品公示
export function maintenanceNew(shopId) {
  return request({
    url: "/shop/data/maintenance/control/new?shopId=" + shopId,
    method: "get",
    cancelToken: new CancelToken(function executor(c) {
      store.dispatch("setCancel", c);
    }),
  });
}
// sop--信息公示
export function infoPublic(shopId) {
  return request({
    url: "/shop/data/info/public?shopId=" + shopId,
    method: "get",
  });
}
// sop--人员管理
export function staffManage(shopId) {
  return request({
    url: "/shop/data/staff/control?shopId=" + shopId,
    method: "get",
  });
}
// sop--加工环境
export function workEnv(shopId) {
  return request({
    url: "/shop/data/work/environment?shopId=" + shopId,
    method: "get",
  });
}

// sop--原材料
export function material(shopId) {
  return request({
    url: "/shop/data/material/control?shopId=" + shopId,
    method: "get",
  });
}

// sop--加工过程
export function workProcess(shopId) {
  return request({
    url: "/shop/data/work/process?shopId=" + shopId,
    method: "get",
  });
}
// sop--设施设备
export function maintenance(shopId) {
  return request({
    url: "/shop/data/maintenance/control?shopId=" + shopId,
    method: "get",
  });
}
// 跑马灯
export function notice() {
  return request({
    url: "/head/quarter/data/horse/race/lamp",
    method: "get",
  });
}
// 实时报警
export function realAlarm(shopId) {
  return request({
    url: "/head/quarter/data/real/alarm?shopId=" + shopId,
    method: "get",
  });
}

export function thirtyAlarmList() {
  return request({
    url: "/head/quarter/data/near/thirty/alarm/list",
    method: "get",
  });
}
// 日管控

export function dayRecord(date) {
  return request({
    url: "/head/quarter/data/daily/control?date=" + date,
    method: "get",
  });
}
// 日管控弹窗左侧列表
export function dayLeftList(date) {
  return request({
    url: "/head/quarter/data/shop/daily/control?date=" + date,
    method: "get",
  });
}
// 日管控弹窗右侧列表
export function dayRightList(id) {
  return request({
    url: "/head/quarter/data/daily/control/detail?id=" + id,
    method: "get",
  });
}
// 周排查
export function weekRecord(date) {
  return request({
    url: "/head/quarter/data/shop/weekly?date=" + date,
    method: "get",
  });
}
export function weekDetail(id) {
  return request({
    url: "/head/quarter/data/weekly/info?id=" + id,
    method: "get",
  });
}
// 周排查本周排查发现问题
export function weekProblem(params) {
  return request({
    url: "/head/quarter/data/weekly/info/topic/record",
    method: "get",
    params,
  });
}
export function weekRectify(params) {
  return request({
    url: "/head/quarter/data/weekly/info/problem/topic",
    method: "get",
    params,
  });
}
export function conversionDate(time) {
  return request({
    url: "/head/quarter/data/period?time=" + time,
    method: "get",
  });
}
// 月

export function monthRecord() {
  return request({
    url: "/head/quarter/data/month/report",
    method: "get",
  });
}
export function monthRecordSummary() {
  return request({
    url: "/head/quarter/data/month/summary",
    method: "get",
  });
}
// 月报告详情-供货商

export function monthSupplierTable(recordId) {
  return request({
    url: "/head/quarter/data/month/report/info/supplier?recordId=" + recordId,
    method: "get",
  });
}
// 食品安全日管控
export function monthSafeTable(recordId) {
  return request({
    url: "/head/quarter/data/month/report/info/check?recordId=" + recordId,
    method: "get",
  });
}
export function closeAlarmAll(data) {
  return request({
    url: "/head/quarter/data/handle/event/record",
    method: "put",
    data,
  });
}

// 设备概览实时监控

export function realMonit(shopId) {
  return request({
    url: "/shop/data/real/monitor?shopId=" + shopId,
    method: "get",
  });
}

// 城市食堂列表
export function cityOfShops() {
  return request({
    url: "/jsdb/dashboard/city/of/shops",
    method: "get",
  });
}

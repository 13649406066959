<template>
  <div class="chart" ref="line"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      widthPix: 1,
    };
  },
  props: {
    showLegend: {
      default: true,
    },
    yName: {
      default: "单位：人",
    },
  },
  methods: {
    initChart(series, category) {
      console.log(series, category, 5555);

      let myChart = null;
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose();
      }
      var chartDom = this.$refs.line;
      myChart = echarts.init(chartDom);
      var option;
      option = {
        legend: {
          show: this.showLegend,
          right: 20 * this.widthPix,
          top: 10 * this.widthPix,
          itemWidth: 4 * this.widthPix,
          itemHeight: 4 * this.widthPix,
          borderRadius: 2 * this.widthPix,
          textStyle: {
            color: "#AEC3DC",
            fontSize: 12 * this.widthPix,
          },
        },
        xAxis: {
          type: "category",
          data: category,

          axisTick: {
            show: false,
          },

          axisLabel: {
            fontFamily: "Alibaba PuHuiTi 2.0",
            color: "#BCD2ED",
            fontSize: 12 * this.widthPix,
            // rotate: -50,
            interval: 0,
            color: "#A1ABB2",
            lineHeight: 20 * this.widthPix,
            formatter: function (value) {
              const first = value.slice(0, 8);
              const last = value.slice(8);
              return first + "\n" + last;
            },
          },
          aixsLine: {
            show: true,
            lineStyle: {
              color: "#3C5787",
            },
          },
        },

        yAxis: {
          type: "value",
          name: this.yName,
          nameGap: 13,
          nameTextStyle: {
            color: "#AEC3DC",
            fontSize: 12,
          },
          // max:100,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(178, 194, 211, 0.2)",
            },
          },
          axisLabel: {
            color: "#AEC3DC",
            fontSize: 12 * this.widthPix,
          },
        },
        grid: {
          left: 40 * this.widthPix,
          right: 0 * this.widthPix,
          top: 40 * this.widthPix,
          bottom: 60 * this.widthPix,
        },
        barWidth: 12 * this.widthPix,
        series: series,
      };
      myChart.clear();
      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.widthPix =
      window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    // this.initChart();
  },
};
</script>
<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
}
</style>
